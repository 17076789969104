<template>
    <section>
        <heading :text="text" :justify="justify" :heading="heading"></heading>
        <text-paragraph/>
    </section>
</template>

<script>
import Heading from '../../components/element/text/heading.vue'
import textParagraph from '../../components/element/text/paragraph.vue'
export default {
    name: 'preview-blog',
    components: {
        Heading,
        textParagraph
    },
    data () {
        return {
            blog: [
                {
                    ulid:"",
                    ulidpage:"01EYNB80RR9K7MDVYPABKCR0HS",
                    title:"Section card-product",
                    slugname:"",
                    datepublished:"2021-01-01 16:53:01",
                    datemodified:"2021-01-01 16:53:01",
                    blocks: [
                        {
                            order: 1,
                            name : "titre",
                            element: "text-title",
                            data: {
                                text: 'Préparez vos armes',
                                justify:'right',
                                heading: 'h1'
                            }
                        },
                        {
                            order: 2,
                            name :"description des produits",
                            element : "text-paragraph",
                            data : {
                                text: "Ceci est un message pour tous les joueurs: 'Préparez-vous' ",
                                justify:'right'
                            }
                        }
                    ]
                }
            ],
            
        }
    }
}
</script>